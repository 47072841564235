
.navbar-dark {
  background: #2B2E4A;
}

.navbar .navbar-nav .nav-link {
    color: #2B2E4A;
    font-size: 1.1em;
    
  }
.navbar .navbar-nav .nav-link:hover {
    color: #808080;
  }
.sm-icons .nav-item {
    flex-direction: row;
  }

.flexbox {
  flex-direction: row;
}

  @media only screen and (max-width: 960px) {
    .sm-icons .nav-item  {
          flex-direction: row;
          padding-right: 1em;
          padding-left: 10px;
        }
    .flexbox {
          flex-direction: row;
        }
    
    
      }
