.Skill {
    
    display: inline-block;
    text-align: center;
    width: 80px;
    font-size: small;
}

.Skill img {
    width: 40px;
    height: 40px;
    
}

.Skill caption {
    display: block;
}