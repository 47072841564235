.projects-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0px 0px 10px rgb(255, 255, 255);
}

.projects-container h2 {
    text-align: center;
    color: #ffffff;
}

.project {
    margin-bottom: 20px;
}

.project a {
    text-decoration: none;
    color: #ffffff;
}

.project img {
    width: 100%;
    height: auto;
}
